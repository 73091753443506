import { Locale, createI18n } from 'vue-i18n'
import type { DefineNumberFormat } from 'vue-i18n'

type I18n = ReturnType<typeof setupI18n>

function generateNumberFormat(region: string, locales: string[], format: DefineNumberFormat): Record<string, DefineNumberFormat> {
    return locales.reduce((item, locale) => {
        item[`${locale}-${region}`] = format

        return item
    }, {} as Record<string, DefineNumberFormat>)
}

function overrideNumberFormat(i18n: I18n) {
    const defualtN = i18n.global.n

    type nParameters = Parameters<typeof defualtN>

    i18n.global.n = (...args: [nParameters[0]] | [number, nParameters[1]] | [number, nParameters[1], nParameters[2]]): ReturnType<typeof defualtN> => {
        const formattedNumber = defualtN(...(args as nParameters))
        const [, region] = i18n.global.locale.value.split('-', 2)

        if (['EE', 'LV', 'LT', 'FI'].includes(region)) {
            return formattedNumber.replace(',', '.')
        }

        return formattedNumber
    }
}

function pluralRussianRule(choice: number, choicesLength: number) {
    if (choice === 0) {
        return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1
    if (!teen && endsWithOne) {
        return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
    }

    return choicesLength < 4 ? 2 : 3
}

export function setupI18n(options = { locale: 'en' }) {
    const bundle: any = {}
    const messages: any = import.meta.glob('./locales/*.json', { eager: true })

    for (const path in messages) {
        const locale = path.split('/').pop()?.split('.')[0] as string
        const [, region] = options.locale.split('-', 2)

        const bundleLocale = region ? `${locale}-${region}` : locale

        bundle[bundleLocale] = messages[path].default
    }

    const i18n = createI18n({
        legacy: false,
        locale: options.locale,
        fallbackLocale: 'en-EE',
        // missingWarn: false,
        // fallbackWarn: false,
        messages: bundle,
        numberFormats: {
            ...generateNumberFormat('EE', ['et', 'ru', 'en'], {
                currency: {
                    style: 'currency', currency: 'EUR',
                },
            }),
            ...generateNumberFormat('LT', ['lt', 'ru', 'en'], {
                currency: {
                    style: 'currency', currency: 'EUR',
                },
            }),
            ...generateNumberFormat('LV', ['lv', 'ru', 'en'], {
                currency: {
                    style: 'currency', currency: 'EUR',
                },
            }),
            ...generateNumberFormat('FI', ['fi', 'en', 'ru'], {
                currency: {
                    style: 'currency', currency: 'EUR',
                },
            }),
        },
        pluralRules: {
            'ru-EE': pluralRussianRule,
            'ru-LV': pluralRussianRule,
            'ru-LT': pluralRussianRule,
            'ru-FI': pluralRussianRule,
        },
    })

    overrideNumberFormat(i18n)

    return i18n
}

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
    // const messages = await import(
    //     /* @vite-ignore */ `./locales/${locale}.json`
    // )

    // // set locale and locale message
    // i18n.global.setLocaleMessage(locale, messages.default || messages)

    // return nextTick()
}
