import { EcommerceAction, event, purchase } from 'vue-gtag'
import { Product, ProductVariant } from '@interfaces/product.interface'
import { Order } from '@interfaces/order.interface'

export interface ItemList {
    id: string
    name: string
    index?: number
}

const productToGoogleProduct = (product: Product, itemList?: Partial<ItemList>) => {
    const item: Gtag.Item = {
        item_id: String(product.id),
        item_name: product.title,
        price: product.price,
        currency: 'EUR',
    }

    if (product.brand) {
        item.item_brand = product.brand.name
    }

    const categories = product.categories?.slice(0, 5) || []

    for (let i = 0; i < categories.length; i++) {
        const key = i === 0 ? 'item_category' : `item_category${i + 1}`

        item[key] = categories[i].name
    }

    if (itemList) {
        item.item_list_id = itemList?.id
        item.item_list_name = itemList?.name
        item.index = itemList?.index
    }

    return item
}

const productVariantToGoogleProduct = (productVariant: ProductVariant, itemList?: Partial<ItemList>) => {
    const item: Gtag.Item = {
        item_id: String(productVariant.product!.id),
        item_name: productVariant.product!.title,
        price: productVariant.final_price,
        currency: 'EUR',
    }

    if (productVariant.product?.brand) {
        item.item_brand = productVariant.product.brand.name
    }

    const categories = productVariant.product?.categories?.slice(0, 5) || []

    for (let i = 0; i < categories.length; i++) {
        const key = i === 0 ? 'item_category' : `item_category${i + 1}`

        item[key] = categories[i].name
    }

    if (itemList) {
        item.item_list_id = itemList?.id
        item.item_list_name = itemList?.name
        item.index = itemList?.index
    }

    return item
}

export function useTracking() {
    function trackProductsListView(
        products: Product[],
        itemList?: ItemList,
        pagination?: { page: number; perPage: number },
    ) {
        event('view_item_list', {
            items: products.map((product, index) => {
                const paginationIndex = (pagination?.page && pagination.perPage) ? (pagination.page - 1) * pagination.perPage : 0

                return productToGoogleProduct(product, {
                    ...itemList,
                    index: paginationIndex + index,
                })
            }),
            item_list_id: itemList?.id,
            item_list_name: itemList?.name,
        })
    }

    function trackProductVariantsListView(
        productVariants: ProductVariant[],
        itemList?: ItemList,
        pagination?: { page: number; perPage: number },
    ) {
        event('view_item_list', {
            items: productVariants.map((variant, index) => {
                const paginationIndex = (pagination?.page && pagination.perPage) ? (pagination.page - 1) * pagination.perPage : 0

                return productVariantToGoogleProduct(variant, {
                    ...itemList,
                    index: paginationIndex + index,
                })
            }),
            item_list_id: itemList?.id,
            item_list_name: itemList?.name,
        })
    }

    function trackPurchase(order: Order) {
        purchase({
            transaction_id: order.nr,
            items: order.items!.map((item): Gtag.Item => ({
                item_id: String(item.product_id),
                item_name: item.title,
                quantity: item.quantity,
                price: item.price,
            })),
            value: order.total,
            tax: order.total_tax,
            shipping: order.shipping_net + order.shipping_tax,
            currency: 'EUR',
        } as EcommerceAction & { currency?: string })

        event('conversion', {
            send_to: 'AW-11108395809',
            transaction_id: order.nr,
            currency: 'EUR',
            value: order.total,
        })
    }

    function trackAddToCart(product: Product, quantity: number, itemList?: ItemList) {
        event('add_to_cart', {
            items: [{
                ...productToGoogleProduct(product, itemList),
                quantity,
            }],
            currency: 'EUR',
            value: product.price * quantity,
        })
    };

    const trackAddToWishlist = (productVariant: ProductVariant, itemList?: ItemList) => {
        event('add_to_wishlist', {
            items: [
                {
                    ...productVariantToGoogleProduct(productVariant, itemList),
                },
            ],
            currency: 'EUR',
            value: productVariant.final_price,
        })
    }

    return {
        trackProductsListView,
        trackProductVariantsListView,
        trackPurchase,
        trackAddToCart,
        trackAddToWishlist,
    }
}
