import axios from 'axios'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import * as Sentry from "@sentry/vue"
import { ItemList, useTracking } from '@/Composables/tracking'

interface CartItem {
    id: string
    name: string
    product: {
        id: number
        image_url: string
        maximum_quantity: number
        price: number
        product_url: string
        retail_price: number
        sale_price: number | null
        sku: string
        title: string
        variant: string
        variant_id: number
    }
    quantity: number
}

interface CartMeta {
    vat: number
    coupons: {
        coupon_id: number
        coupon_code: string
        discount: number
    }[]
}

export const useCartStore = defineStore('cart', () => {
    const { trackAddToCart } = useTracking()

    const show = ref(false)
    const loaded = ref(false)
    const itemsLoading = ref(false)
    const items = ref<CartItem[]>([])
    const meta = ref<CartMeta | null>()

    const subtotal = computed(() => {
        return items.value.reduce((amount, item) => amount + (item.quantity * item.product.price), 0)
    })

    const discount = computed(() => {
        return (meta.value?.coupons || []).reduce((amount, coupon) => {
            if (coupon.discount) {
                return amount + coupon.discount
            }

            return amount
        }, 0)
    })

    const defaultVat = computed(() => {
        return meta.value?.vat
    })

    const saveAmount = computed(() => {
        return items.value.reduce((amount, item) => {
            if (item.product.sale_price) {
                return amount + (item.quantity * (item.product.retail_price - item.product.sale_price))
            }

            return amount
        }, 0)
    })

    const setCart = (oldItems: CartItem[], newMeta: CartMeta) => {
        items.value = oldItems
            .map((item) => {
                if (item.quantity > 0 && item.product.maximum_quantity === 0) {
                    item.quantity = 0
                }

                if (item.quantity > item.product.maximum_quantity) {
                    item.quantity = item.product.maximum_quantity
                }

                return item
            })
            .sort((a, b) => a.product.title.localeCompare(b.product.title))

        meta.value = newMeta
    }

    const getCart = () => {
        if (loaded.value) {
            return
        }

        itemsLoading.value = true

        axios.get(`/ajax/cart`)
            .then((response) => {
                setCart(response.data.data, response.data.meta)

                loaded.value = true
                itemsLoading.value = false
            })
            .catch((error) => {
                itemsLoading.value = false

                console.error(error)
            })
    }

    const addItem = (productId: number, variantId: number, quantity: number, meta?: { tracking?: { itemList?: ItemList } }) => {
        return new Promise((resolve, reject) => {
            axios.post<JsonResource<CartItem>>(`/ajax/cart`, { product_id: productId, variant_id: variantId, quantity })
                .then((response) => {
                    let updatedItems = [...items.value]

                    // Add item to the list
                    const item = response.data.data
                    const index = updatedItems.findIndex((x) => x.id === item.id)

                    if (index === -1) {
                        updatedItems.push(item)
                    } else {
                        updatedItems = updatedItems.map((x) => {
                            if (item.id === x.id) {
                                return {
                                    ...x,
                                    ...item,
                                }
                            }

                            return x
                        })
                    }

                    // Update cart
                    setCart(updatedItems, response.data.meta)

                    // Open cart
                    show.value = true

                    // Track event
                    trackAddToCart(item.product as any, item.quantity, meta?.tracking?.itemList)

                    for (const event of response.data.meta.fbq_events) {
                        window.fbq(event.action, event.name, event.custom_data, event.event_data)
                    }

                    resolve(item)
                })
                .catch((error) => {
                    Sentry.captureException(error)

                    reject(error)
                })
        })
    }

    const updateQuantity = (id: string, quantity: number) => {
        axios.put(`/ajax/cart/${id}`, { quantity })
            .then((response) => {
                const item = response.data.data
                const newItems = items.value.map((x) => {
                    if (item.id === x.id) {
                        return {
                            ...x,
                            ...item,
                        }
                    }

                    return x
                })

                setCart(newItems, response.data.meta)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const deleteItem = (id: string) => {
        axios.delete(`/ajax/cart/${id}`)
            .then((response) => {
                const item = items.value.find((item) => item.id === id)

                if (item) {
                    // window.gtag('event', 'remove_from_cart', {
                    //     items: [
                    //         {
                    //             item_id: item.product.id,
                    //             item_name: item.product.title,
                    //             quantity: item.quantity,
                    //             price: item.product.price,
                    //             currency: 'EUR',
                    //         },
                    //     ],
                    //     currency: 'EUR',
                    //     value: item.quantity * item.product.price,
                    // })
                }

                const newItems = items.value.filter((item) => {
                    return item.id !== id
                })

                setCart(newItems, response.data.meta)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const applyCoupon = (code: string) => {
        return new Promise((resolve, reject) => {
            axios.post(`/ajax/cart/coupons`, { code })
                .then((response) => {
                    const coupon = response.data.data

                    meta.value!.coupons = [coupon]

                    resolve(coupon)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    }

    const removeCoupon = (code: string) => {
        return new Promise((resolve, reject) => {
            axios.delete(`/ajax/cart/coupons/${code}`)
                .then((response) => {
                    meta.value!.coupons = []

                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    }

    return {
        show,
        loaded,
        items,
        itemsLoading,
        meta,
        subtotal,
        defaultVat,
        discount,
        saveAmount,
        getCart,
        addItem,
        updateQuantity,
        deleteItem,
        applyCoupon,
        removeCoupon,

        // Composable
        trackAddToCart,
    }
})
