import './bootstrap'
import '../css/app.css'

import 'lazysizes'

import * as Sentry from "@sentry/vue"
import { createApp, h, DefineComponent } from 'vue'
import { createPinia } from 'pinia'
import { loadLocaleMessages, setupI18n } from './i18n'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import VueGtag, { PluginOptions as VueGtagPluginOptions } from 'vue-gtag'
import ApplicationLayout from './Layouts/ApplicationLayout.vue'

createInertiaApp({
    // title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'))

        page.default.layout = page.default.layout || ApplicationLayout

        return page
    },
    setup({ el, App, props, plugin }) {
        const locale = props.initialPage.props.app.localization.locale

        const pinia = createPinia()
        const i18n = setupI18n({ locale })

        loadLocaleMessages(i18n, 'en')
        loadLocaleMessages(i18n, locale)

        const app = createApp({ render: () => h(App, props) })

        app.use(plugin)
            .use(pinia)
            .use(i18n)
            .use(ZiggyVue, Ziggy)
            .use(VueGtag, {
                deferScriptLoad: true,
                config: { id: "G-N9HCVWBKDT" },
                // includes: [{ id: 'AW-11108395809' }],
            } as VueGtagPluginOptions & { deferScriptLoad?: boolean })

        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                dsn: "https://e465d1cd7932fd9ee3adeaafeb697fd3@o394041.ingest.sentry.io/4506141048700928",
                ignoreErrors: [
                    // Random plugins/extensions
                    "top.GLOBALS",
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    "originalCreateNotification",
                    "canvas.contentDocument",
                    "MyApp_RemoveAllHighlights",
                    "http://tt.epicplay.com",
                    "Can't find variable: ZiteReader",
                    "jigsaw is not defined",
                    "ComboSearch is not defined",
                    "http://loading.retry.widdit.com/",
                    "atomicFindClose",
                    // Facebook borked
                    "fb_xd_fragment",
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                    // reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268
                    "bmi_SafeAddOnload",
                    "EBCallBackMessageReceived",
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    "conduitPage",
                ],
                denyUrls: [
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
                tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/calypso\.ee\/api/,
                    /^https:\/\/calypso\.ee\/ajax/,
                    /^https:\/\/calypso\.lv\/api/,
                    /^https:\/\/calypso\.lv\/ajax/,
                    /^https:\/\/calypso\.lt\/api/,
                    /^https:\/\/calypso\.lt\/ajax/,
                    /^https:\/\/calypsojewelry\.com\/api/,
                    /^https:\/\/calypsojewelry\.com\/ajax/,
                ],
                tracesSampleRate: 0.1,
            })
        }

        app.mount(el)
    },
    progress: {
        color: '#4B5563',
    },
})
