<script setup lang="ts">
import { ref, onUnmounted, onMounted, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { Link, usePage } from '@inertiajs/vue3'
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverGroup, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import ApplicationLogo from '../ApplicationLogo.vue'
import ApplicationLogoIcon from '../ApplicationLogoIcon.vue'
import { useCartStore } from '@/Stores/cart.store'
import { Cog6ToothIcon, UserIcon, HeartIcon, ShoppingCartIcon, XMarkIcon, Bars3Icon } from '@heroicons/vue/24/outline'

const JewelleryMenu = defineAsyncComponent(() => import('../Menu/Jewellery/JewelleryMenu.vue'))
const JewelleryMobileMenu = defineAsyncComponent(() => import('../Menu/Jewellery/JewelleryMobileMenu.vue'))

const page = usePage()
const { t } = useI18n()
const cart = useCartStore()

const showMenu = ref(false)
const showDesktopMenu = ref(false)

const onNavigate = () => {
    showMenu.value = false
}

onMounted(() => {
    document.addEventListener('inertia:start', onNavigate)
})

onUnmounted(() => {
    document.removeEventListener('inertia:start', onNavigate)
})
</script>

<template>
    <div class="bg-white border-b border-grayscale-100">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="flex items-center h-16">
                <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                <button
                    type="button"
                    class="p-2 -ml-2 bg-white rounded-md text-grayscale-500 hover:text-grayscale-700 lg:hidden"
                    @click="showMenu = true"
                >
                    <span class="sr-only">Open menu</span>
                    <Bars3Icon
                        class="w-6 h-6 stroke-2"
                        aria-hidden="true"
                    />
                </button>

                <!-- Logo -->
                <div class="flex ml-4 text-grayscale-900 lg:ml-0">
                    <Link :href="route('home')">
                    <span class="sr-only">Calypso</span>
                    <ApplicationLogo class="hidden w-auto h-10 sm:inline" />
                    <ApplicationLogo class="w-auto h-9 sm:hidden" />
                    </Link>
                </div>

                <!-- Flyout menus -->
                <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
                    <div class="flex h-full space-x-8">
                        <Link
                            :href="route('home')"
                            class="flex items-center pt-px -mb-px font-medium border-b-2 border-transparent text-grayscale-900 text-p1 hover:text-grayscale-700 hover:border-grayscale-200"
                        >{{ t('layout.navbar-main.home') }}</Link>

                        <Popover
                            v-slot="{ open, close }"
                            class="z-30 flex"
                        >

                            <div class="relative flex">
                                <PopoverButton
                                    type="button"
                                    :class="['flex items-center text-p1 font-medium pt-px -mb-px border-b-2 transition-colors duration-200 ease-out focus:outline-none', open ? 'border-primary-600 text-primary-600' : 'border-transparent text-grayscale-900 hover:text-grayscale-600 hover:border-grayscale-200']"
                                >
                                    {{ t('layout.navbar-main.catalog') }}
                                </PopoverButton>
                            </div>

                            <!-- <PopoverOverlay class="fixed inset-0 z-0 transition-opacity bg-gray-500 bg-opacity-75" /> -->

                            <transition
                                enter-active-class="transition duration-200 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition duration-150 ease-in"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                            >
                                <PopoverPanel class="absolute inset-x-0 text-p2 top-full text-slate-500">
                                    <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                                    <div
                                        class="absolute inset-0 bg-white shadow top-1/2"
                                        aria-hidden="true"
                                    ></div>

                                    <div class="relative bg-white">
                                        <div class="px-8 mx-auto max-w-7xl">
                                            <JewelleryMenu
                                                :close-menu="close"
                                                class="py-10"
                                            />
                                        </div>
                                    </div>
                                </PopoverPanel>
                            </transition>
                        </Popover>

                        <Link
                            :href="route('brands')"
                            class="flex items-center pt-px -mb-px font-medium border-b-2 border-transparent text-p1 text-grayscale-900 hover:text-primary-600 hover:border-grayscale-200"
                        >{{ t('layout.navbar-main.brands') }}</Link>
                        <Link
                            :href="route('reviews', { type: 'shop' })"
                            class="flex items-center pt-px -mb-px font-medium border-b-2 border-transparent text-p1 text-grayscale-900 hover:text-primary-600 hover:border-grayscale-200"
                        >{{ t('layout.navbar-main.reviews') }}</Link>
                    </div>
                </PopoverGroup>

                <div class="flex items-center ml-auto">
                    <div class="hidden sm:flex">
                        <a
                            :href="$page.props.user ? route('profile.orders') : route('login')"
                            class="p-2 text-grayscale-500 hover:text-grayscale-800"
                        >
                            <span class="sr-only">{{ t('layout.navbar-main.login') }}</span>
                            <UserIcon class="w-6 h-6 stroke-[1.4]" />
                        </a>
                    </div>

                    <div
                        v-if="$page.props.user?.is_admin"
                        class="flex"
                    >
                        <a
                            :href="route('manage.catalog-categories.index')"
                            class="p-2 text-grayscale-500 hover:text-grayscale-900"
                        >
                            <span class="sr-only">Management</span>
                            <Cog6ToothIcon class="w-6 h-6 stroke-[1.4]" />
                        </a>
                    </div>

                    <div class="flex">
                        <Link
                            :href="route('wishlist')"
                            class="p-2 text-grayscale-500 hover:text-grayscale-900"
                        >
                        <span class="sr-only">{{ t('layout.navbar-main.wishlist') }}</span>
                        <HeartIcon class="w-6 h-6 stroke-[1.4]" />
                        </Link>
                    </div>

                    <!-- Cart -->
                    <div class="flex">
                        <a
                            href="#"
                            class="flex items-center p-2 -mr-2 group"
                            @click="cart.show = true"
                        >
                            <ShoppingCartIcon class="flex-shrink-0 w-6 h-6 text-grayscale-500 group-hover:text-grayscale-900 stroke-[1.4]" />
                            <span class="ml-2 text-p1 text-grayscale-700 group-hover:text-grayscale-900">{{ cart.loaded ? cart.items.length : $page.props.cart.quantity }}</span>
                            <span class="sr-only">items in cart, view bag</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <TransitionRoot
        as="template"
        :show="showMenu"
    >
        <Dialog
            as="div"
            class="relative z-50 lg:hidden"
            aria-labelledby="menu-title"
            role="dialog"
            aria-modal="true"
            @close="showMenu = false"
        >
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 flex">
                <TransitionChild
                    as="template"
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
                        <div class="flex px-4 pt-5 pb-2">
                            <button
                                type="button"
                                class="inline-flex items-center justify-center p-2 -m-2 rounded-md text-grayscale-500 hover:text-grayscale-700 hover:bg-grayscale-50 focus:outline-none focus:ring-2 focus:ring-primary-600"
                                @click="showMenu = false"
                            >
                                <span class="sr-only">Close menu</span>
                                <XMarkIcon
                                    class="w-6 h-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>

                        <JewelleryMobileMenu class="mt-2" />
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
