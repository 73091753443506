import { usePage } from '@inertiajs/vue3'
import { Product, ProductVariant } from '@interfaces/product.interface'
import dayjs from 'dayjs'
import { round } from 'lodash-es'
import { useHelpers } from './helpers'
import { computed, inject } from 'vue'
import type ziggyRoute from 'ziggy-js'

export function useJsonLd() {
    const page = usePage()
    const route = inject<typeof ziggyRoute>('route')

    const { asset } = useHelpers()

    const breadcrumbs = computed(() => page.props.layout.breadcrumbs || [])

    function logoSchema() {
        return JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Calypso OÜ",
            "alternateName": "Calypso",
            "url": page.props.app.root_url,
            "logo": asset('/images/company-logo.png'),
            "sameAs": [
                "https://www.facebook.com/CalypsoEesti/",
                "https://www.instagram.com/calypso_eesti/",
            ],
        })
    }

    function breadcrumbsSchema() {
        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': breadcrumbs.value.map((breadcrumb, i) => ({
                '@type': 'ListItem',
                'position': i + 1,
                'name': breadcrumb.title,
                'item': breadcrumb.url,
            })),
        })
    }

    function productSchema(product: any, description: string, media: Array<string>, variants: any[], reviews?: { rating_average: number, reviews_count: number }) {
        const schema: any = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            'productID': product.id,
            'name': product.title,
            'image': media,
            'description': description,
            'sku': product.sku,
            'locale': page.props.app.localization.locale,
            'offers': {
                '@type': 'Offer',
                'url': route!('product', product.slug_path || product.slug),
                'priceCurrency': 'EUR',
                'price': product.price,
                'priceValidUntil': dayjs().add(1, 'year').format('YYYY-MM-DD'),
                'itemCondition': 'https://schema.org/NewCondition',
                'availability': (variants.reduce((amount, { quantity }) => amount + quantity, 0)) ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            },
        }

        if (product.brand) {
            schema.brand = {
                '@type': 'Brand',
                'name': product.brand.name,
            }
        }

        if (product.ean) {
            schema.gtin13 = product.ean
        }

        if (reviews?.reviews_count) {
            schema.aggregateRating = {
                '@type': 'AggregateRating',
                'ratingValue': round(reviews.rating_average, 1),
                'bestRating': 5,
                'worstRating': 1,
                'ratingCount': reviews.reviews_count,
            }
        }

        return schema
    }

    function productVariantSchema(variant: ProductVariant, description?: string | null) {
        const schema: any = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            'productID': variant.product!.id,
            'name': variant.product!.title,
            'image': variant.product?.media?.map((media) => media.url) || [],
            'sku': variant.sku,
            'locale': page.props.app.localization.locale,
            'offers': {
                '@type': 'Offer',
                'url': route!('product', variant.product!.slug),
                'priceCurrency': 'EUR',
                'price': variant.final_price,
                'priceValidUntil': dayjs().add(1, 'year').format('YYYY-MM-DD'),
                'itemCondition': 'https://schema.org/NewCondition',
                'availability': variant.quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            },
        }

        if (description) {
            schema.description = description
        }

        if (variant.product?.brand) {
            schema.brand = {
                '@type': 'Brand',
                'name': variant.product.brand.name,
            }
        }

        if (variant.gtin) {
            schema.gtin13 = variant.gtin
        }

        if (variant.reviews_count && variant.reviews_avg_rating) {
            schema.aggregateRating = {
                '@type': 'AggregateRating',
                'ratingValue': round(variant.reviews_avg_rating, 1),
                'bestRating': 5,
                'worstRating': 1,
                'ratingCount': variant.reviews_count,
            }
        }

        return schema
    }

    function itemListSchema(products: Array<Product>) {
        const schema: any = {
            '@context': 'https://schema.org/',
            '@type': 'ItemList',
            'itemListElement': products.map((product) => productSchema(
                product,
                '',
                product.media?.map((media) => media.url) || [],
                product.variants || [],
                {
                    rating_average: product.rating_average || 0,
                    reviews_count: product.reviews_count || 0,
                },
            )),
        }

        return schema
    }

    function productVariantsItemListSchema(variants: Array<ProductVariant>) {
        const schema: any = {
            '@context': 'https://schema.org/',
            '@type': 'ItemList',
            'itemListElement': variants.map((variant) => productVariantSchema(
                variant,
                null,
            )),
        }

        return schema
    }

    return { logoSchema, breadcrumbsSchema, productSchema, itemListSchema, productVariantsItemListSchema }
}
