import { LinkType } from '@enums/link-type.enum'
import { usePage } from '@inertiajs/vue3'

export function useHelpers() {
    const page = usePage()

    function asset(path: string) {
        const cleanPath = path.replace(/^\//, '')

        return `${page.props.app.asset_url}/${cleanPath}`
    }

    function socialLink(type: LinkType): string {
        switch (type) {
            case LinkType.Facebook:
                return 'https://www.facebook.com/CalypsoEesti/'
            case LinkType.Instagram:
                return 'https://instagram.com/calypso_eesti/'
        }

        throw new Error(`Unknown link type: ${type}`)
    }

    return { asset, socialLink }
}
