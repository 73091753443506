<template>
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.94887 6.35345C1.62868 5.55631 1.62842 4.77018 2.02327 4.22634C2.73631 3.24451 4.46635 3.42363 5.88773 4.62623C7.32134 5.83929 7.5271 7.74527 7.39647 8.12358C7.27982 8.50641 5.99302 9.92731 4.12021 10.0662C2.26346 10.2036 0.758544 9.33163 0.758718 8.11818C0.758936 7.44593 1.22121 6.81006 1.94887 6.35345Z"
            fill="#FFFFFF" />
        <path
            d="M4.36098 10.8083C5.95769 9.81968 7.83399 10.213 8.1534 10.4541C8.48139 10.6834 9.43518 12.3463 8.98854 14.1703C8.54542 15.9787 7.2511 17.1404 6.09711 16.7652C5.4579 16.5573 4.9961 15.9212 4.78664 15.0881C3.92956 15.1464 3.18179 14.9036 2.78655 14.36C2.07312 13.3785 2.77793 11.7884 4.36098 10.8083Z"
            fill="#FFFFFF" />
        <path
            d="M10.6047 10.4541C10.9242 10.2129 12.8004 9.81974 14.3971 10.8082C15.9801 11.7884 16.685 13.3785 15.9716 14.3601C15.5764 14.9037 14.8286 15.1463 13.9716 15.0881C13.762 15.9213 13.3002 16.5575 12.661 16.7653C11.5071 17.1404 10.2126 15.9788 9.76965 14.1704C9.32288 12.3462 10.2768 10.6833 10.6047 10.4541Z"
            fill="#FFFFFF" />
        <path
            d="M14.6385 10.0662C12.7657 9.92741 11.4789 8.50637 11.3622 8.12359C11.2317 7.74523 11.4375 5.83934 12.871 4.6262C14.2924 3.42359 16.0224 3.24456 16.7355 4.2263C17.1304 4.77019 17.1301 5.55632 16.8098 6.35345C17.5375 6.81011 17.9998 7.44598 18 8.1181C18.0001 9.33159 16.4953 10.2036 14.6385 10.0662Z"
            fill="#FFFFFF" />
        <path
            d="M9.37875 6.68293C8.97864 6.69025 7.22958 5.90569 6.5188 4.16733C5.81429 2.44391 6.17871 0.743204 7.33274 0.368467C7.97204 0.160879 8.71956 0.404073 9.37875 0.955101C10.038 0.40416 10.7855 0.160966 11.4248 0.368467C12.5788 0.743204 12.9432 2.44399 12.2387 4.16733C11.528 5.90564 9.77887 6.69025 9.37875 6.68293Z"
            fill="#FFFFFF" />
        <path
            d="M9.37884 10.089C8.53744 10.089 7.85535 9.4069 7.85535 8.56549C7.85535 7.72409 8.53744 7.04199 9.37884 7.04199C10.2203 7.04199 10.9023 7.72409 10.9023 8.56549C10.9023 9.4069 10.2203 10.089 9.37884 10.089Z"
            fill="#FFFFFF" />
    </svg>

</template>
