<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import { Head, usePage } from '@inertiajs/vue3'
import type { GlobalEvent } from '@inertiajs/core'
import { pageview } from 'vue-gtag'
import { useJsonLd } from '@/Composables/json-ld'
import { useCartStore } from '@/Stores/cart.store'

const page = usePage()
const cart = useCartStore()
const { logoSchema, breadcrumbsSchema } = useJsonLd()

const breadcrumbs = computed(() => page.props.layout.breadcrumbs || [])
const fullUrl = computed(() => page.props.app.url)

const onNavigate = (event: GlobalEvent<"navigate">) => {
    pageview({})

    for (const event of page.props.analytics.fb) {
        window.fbq(event.type, event.name, event.data, event.event)
    }
}

onMounted(() => {
    document.addEventListener('inertia:navigate', onNavigate)

    cart.getCart()
})

onUnmounted(() => {
    document.removeEventListener('inertia:navigate', onNavigate)
})
</script>

<template>
    <Head>
        <link v-if="$page.props.layout.meta?.canonical" rel="canonical" :href="$page.props.layout.meta.canonical">
        <link v-for="{ hreflang, href } in $page.props.layout.i18n.alternate" :key="hreflang" rel="alternate" :hreflang="hreflang" :href="href">

        <meta v-if="$page.props.layout.meta?.robots" name="robots" :content="$page.props.layout.meta.robots">

        <meta property="og:locale" :content="$page.props.app.localization.locale">
        <meta property="og:url" :content="fullUrl">
        <meta property="og:site_name" content="Calypso">

        <!-- Markup -->
        <component :is="'script'" type="application/ld+json">
            {{ logoSchema() }}
        </component>

        <component :is="'script'" v-if="breadcrumbs.length > 0" type="application/ld+json">
            {{ breadcrumbsSchema() }}
        </component>
    </Head>

    <slot></slot>
</template>
