<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import { Popover, PopoverButton, PopoverGroup } from '@headlessui/vue'
import { TruckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { LinkType } from '@enums/link-type.enum'
import FacebookIcon from '../Icons/FacebookIcon.vue'
import InstagramIcon from '../Icons/InstagramIcon.vue'
import { useHelpers } from '@/Composables/helpers'
import PopoverMenuPanel from '../Popover/PopoverMenuPanel.vue'
import PopoverMenuGroup from '../Popover/PopoverMenuGroup.vue'
import PopoverMenuItem from '../Popover/PopoverMenuItem.vue'
import Flag from '../Flag.vue'

const { t } = useI18n()
const { socialLink } = useHelpers()
</script>

<template>
    <div class="font-normal text-grayscale-900 bg-grayscale-50 text-p2">
        <div class="flex items-center h-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="hidden md:flex md:items-center md:mr-8">
                <TruckIcon class="h-4 w-4 -mt-0.5 mr-2" />
                <span class="font-normal">{{ t('layout.navbar-top.free-shipping', { amount: $page.props.cart.free_shipping_threshold }) }}</span>
            </div>

            <!-- Region switch -->
            <PopoverGroup class="flow-root mr-auto">
                <div class="flex items-center -mx-4 divide-x divide-grayscale-100">
                    <Popover class="relative z-40 inline-block px-4 text-left">
                        <PopoverButton class="flex items-center text-p2 group focus:outline-none hover:text-grayscale-900">
                            <Flag
                                class="mr-2"
                                :code="$page.props.app.localization.region_code"
                                alt="Current locale"
                            />
                            <span>{{ $page.props.app.localization.region_name }}</span>
                            <ChevronDownIcon class="flex-shrink-0 w-4 h-4 ml-0.5 -mr-0.5 text-grayscale-400 group-hover:text-grayscale-700" />
                        </PopoverButton>

                        <PopoverMenuPanel class="w-40">
                            <PopoverMenuGroup>
                                <PopoverMenuItem
                                    v-for="(region, regionIdx) in $page.props.layout.i18n.regions"
                                    :key="regionIdx"
                                    as="a"
                                    :href="region.url"
                                    :active="$page.props.app.localization.region_code === region.code"
                                >
                                    <Flag
                                        class="mr-2"
                                        :code="region.code"
                                        :alt="region.name"
                                    />
                                    <span>{{ region.name }}</span>
                                </PopoverMenuItem>
                            </PopoverMenuGroup>
                        </PopoverMenuPanel>
                    </Popover>

                    <Popover class="relative z-40 inline-block px-4 text-left">
                        <PopoverButton class="flex items-center text-p2 group focus:outline-none hover:text-grayscale-900">
                            <span>{{ $page.props.app.localization.language_name }}</span>
                            <ChevronDownIcon class="flex-shrink-0 w-4 h-4 ml-0.5 -mr-0.5 text-grayscale-400 group-hover:text-grayscale-700" />
                        </PopoverButton>

                        <PopoverMenuPanel class="w-40">
                            <PopoverMenuGroup>
                                <PopoverMenuItem
                                    v-for="(language, languageIdx) in $page.props.layout.i18n.languages"
                                    :key="languageIdx"
                                    as="a"
                                    :href="language.url"
                                    :active="$page.props.app.localization.language_code == language.code"
                                >
                                    <span>{{ language.name }}</span>
                                </PopoverMenuItem>
                            </PopoverMenuGroup>
                        </PopoverMenuPanel>
                    </Popover>
                </div>
            </PopoverGroup>

            <!-- Help links -->
            <ul class="hidden mr-10 divide-x divide-grayscale-100 lg:flex">
                <li>
                    <Link
                        class="block px-4 hover:text-grayscale-700"
                        :href="route('shipping')"
                    >{{ t('layout.navbar-top.shipping') }}</Link>
                </li>

                <li>
                    <Link
                        class="block px-4 hover:text-grayscale-700"
                        :href="route('faq')"
                    >{{ t('layout.navbar-top.faq') }}</Link>
                </li>

                <li>
                    <Link
                        class="block px-4 hover:text-grayscale-700"
                        :href="route('contact')"
                    >{{ t('layout.navbar-top.contacts') }}</Link>
                </li>
            </ul>

            <!-- Social -->
            <ul class="flex -mr-4">
                <li>
                    <a
                        class="flex items-center h-10 px-4 text-grayscale-500 hover:text-grayscale-900"
                        :href="socialLink(LinkType.Facebook)"
                        target="blank"
                        aria-label="Facebook"
                    >
                        <FacebookIcon class="h-3.5 w-3.5 mx-px" />
                    </a>
                </li>

                <li class="-ml-4">
                    <a
                        class="flex items-center h-10 px-4 text-grayscale-500 hover:text-grayscale-900"
                        :href="socialLink(LinkType.Instagram)"
                        target="blank"
                        aria-label="Instagram"
                    >
                        <InstagramIcon class="h-3.5 w-3.5 mx-px" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
