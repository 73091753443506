<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import AbstractLayout from './AbstractLayout.vue'
import ApplicationHeader from '@/Components/Layout/ApplicationHeader.vue'
import ApplicationFooter from '@/Components/Layout/ApplicationFooter.vue'
import { useChatWidget } from '@/Composables/chat-widget'
import ChatWidgetButton from '@/Components/ChatWidgetButton.vue'
import PromoLine from '@/Components/Promos/PromoLine.vue'

const CartModal = defineAsyncComponent(() => import('@/Components/Modals/CartModal.vue'))
const CookieConsent = defineAsyncComponent(() => import('@/Components/CookieConsent.vue'))

// const { init } = useChatWidget()

// onMounted(() => {
//     init()
// })
</script>

<template>
    <AbstractLayout>
        <PromoLine
            v-if="$page.props.layout.promo?.top_line.theme && $page.props.layout.promo?.top_line.message"
            :theme="$page.props.layout.promo?.top_line.theme"
        >
            <span v-html="$page.props.layout.promo?.top_line.message"></span>
        </PromoLine>

        <ApplicationHeader />

        <main>
            <slot />
        </main>

        <ApplicationFooter />

        <CartModal />
        <CookieConsent />
        <!-- <ChatWidgetButton /> -->
    </AbstractLayout>
</template>
