<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    code: string,
    size?: string,
    fetchPriority?: 'auto' | 'high' | 'low',
    alt?: string,
}>()

const containerClass = computed(() => {
    switch (props.size) {
        case 'md':
            return 'w-[18px] h-[18px]'
        case 'sm':
        default:
            return 'w-[14px] h-[14px]'
    }
})

const imageWidth = computed(() => {
    switch (props.size) {
        case 'md':
            return 18
        case 'sm':
        default:
            return 14
    }
})
</script>

<template>
    <div :class="['flex-shrink-0 rounded-full overflow-hidden relative box-border shadow-sm before:absolute before:w-full before:h-full before:block before:border before:border-black before:border-opacity-50 before:box-border before:mix-blend-overlay before:rounded-full', containerClass]">
        <img
            :fetchpriority="fetchPriority"
            class="w-full h-full"
            :src="`/images/flags/${code}.svg`"
            :alt="alt"
            :height="imageWidth"
            :width="imageWidth"
        >
    </div>
</template>
